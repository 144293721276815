import { createEnv } from "@t3-oss/env-nextjs";
import { vercel } from "@t3-oss/env-nextjs/presets-zod";
import { z } from "zod";

export const env = createEnv({
  client: {
    NEXT_PUBLIC_MAIN_SITE_BASE_URL: z.string().url(),
    NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY: z.string().min(1),
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: z.string().min(1),
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: z.string().min(1),
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: z.string().min(1),
    NEXT_PUBLIC_SANITY_PROJECT_ID: z.string().min(1),
    NEXT_PUBLIC_SANITY_DATASET: z.string().min(1),
    NEXT_PUBLIC_SANITY_API_VERSION: z.string().min(1),
    NEXT_PUBLIC_SANITY_PERSPECTIVE: z.enum(["previewDrafts", "published", "raw"]),
    NEXT_PUBLIC_SANITY_TOKEN: z.string().min(1),
    NEXT_PUBLIC_TALKJS_APP_ID: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string().min(1),
    // NEXT_PUBLIC_GOOGLE_TIMEZONE_API_KEY: z.string().min(1),
    NEXT_PUBLIC_CANNY_BOARD_TOKEN: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID: z.string().min(1),
    NEXT_PUBLIC_ADIN_WHATSAPP_GROUP_URL: z.string().min(1).optional(),
  },
  shared: {
    NODE_ENV: z.enum(["development", "production"]),
  },
  experimental__runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_MAIN_SITE_BASE_URL: process.env.NEXT_PUBLIC_MAIN_SITE_BASE_URL,
    NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY: process.env.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITE_KEY,
    NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY: process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    NEXT_PUBLIC_CLERK_SIGN_IN_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_IN_URL,
    NEXT_PUBLIC_CLERK_SIGN_UP_URL: process.env.NEXT_PUBLIC_CLERK_SIGN_UP_URL,
    NEXT_PUBLIC_SANITY_PROJECT_ID: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
    NEXT_PUBLIC_SANITY_DATASET: process.env.NEXT_PUBLIC_SANITY_DATASET,
    NEXT_PUBLIC_SANITY_API_VERSION: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
    NEXT_PUBLIC_SANITY_PERSPECTIVE: process.env.NEXT_PUBLIC_SANITY_PERSPECTIVE,
    NEXT_PUBLIC_SANITY_TOKEN: process.env.NEXT_PUBLIC_SANITY_TOKEN,
    NEXT_PUBLIC_TALKJS_APP_ID: process.env.NEXT_PUBLIC_TALKJS_APP_ID,
    NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    // NEXT_PUBLIC_GOOGLE_TIMEZONE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_TIMEZONE_API_KEY,
    NEXT_PUBLIC_CANNY_BOARD_TOKEN: process.env.NEXT_PUBLIC_CANNY_BOARD_TOKEN,
    NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG_ID,
    NEXT_PUBLIC_ADIN_WHATSAPP_GROUP_URL: process.env.NEXT_PUBLIC_ADIN_WHATSAPP_GROUP_URL,
  },
  extends: [vercel()],
});
